exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-dash-app-tsx": () => import("./../../../src/pages/DashApp.tsx" /* webpackChunkName: "component---src-pages-dash-app-tsx" */),
  "component---src-pages-github-tsx": () => import("./../../../src/pages/github.tsx" /* webpackChunkName: "component---src-pages-github-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-pages-tangible-tsx": () => import("./../../../src/pages/tangible.tsx" /* webpackChunkName: "component---src-pages-tangible-tsx" */),
  "component---src-pages-words-tsx": () => import("./../../../src/pages/words.tsx" /* webpackChunkName: "component---src-pages-words-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

